import React, { useMemo, useState } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import ImageIcon from '@mui/icons-material/Image';

import AppRoutes from 'src/routes/appRoutes';
import { ACTIONS, hasRoutePermission } from 'src/services/storage/permissions';

import { Columns, RowAction } from 'src/components/crud/Crud.d';

import Breadcrumb from 'src/components/Breadcrumb';
import CrudAPI from 'src/components/crud/CrudPage';
import { Product } from 'src/pages/marketplace/Products.d';

import PhotosModal from './PhotosModal';

const name = {
    singular: 'Produto',
    plural: 'Produtos',
};

const columns: Columns<Product> = [
    {
        accessorKey: 'image',
        header: '',
        enableEditing: false,
        enableSorting: false,
        enableColumnFilter: false,
        enableColumnActions: false,
        size: 100,
        Cell: ({ row }) => (
            <img
                src={row.original.thumbnail}
                style={{
                    height: '100px',
                    width: '100px',
                    borderRadius: '4px',
                    objectFit: 'cover',
                }}
            />
        ),
    },
    {
        accessorKey: 'name',
        header: 'Nome',
        size: 100,
    },
    {
        accessorKey: 'description',
        header: 'Descrição',
        size: 300,
        field: {
            type: 'text',
            multiline: true,
        },
    },
    {
        accessorKey: 'cost',
        header: 'Custo',
        size: 50,
    },
    {
        accessorKey: 'is_active',
        header: 'Ativo',
        size: 50,
        field: {
            type: 'check',
            default: true,
        },
        type: 'check',
    },
];

const endpoint = '/api/marketplace/products/';

const Products = () => {
    /**
     * PHOTO UPLOAD
     */
    const [photosOpen, setPhotosOpen] = useState(false);
    const [photosEntity, setPhotosEntity] = useState<Product | null>(null);

    const openPhotos = (entity: Product) => {
        setPhotosEntity(entity);
        setPhotosOpen(true);
    };

    /**
     * ROW ACTIONS
     */
    const photosRowAction: RowAction<Product> = {
        onClick: openPhotos,
        tooltip: 'Fotos',
        icon: ImageIcon,
        color: 'primary',
    };

    const extraRowActions = useMemo<RowAction<Product>[]>(
        () => (hasRoutePermission(AppRoutes.ProductsPhotos, ACTIONS.VIEW) ? [photosRowAction] : []),
        []
    );

    /**
     * RENDER COMPONENT
     */
    return (
        <>
            <Stack direction={'row'} justifyContent={'space-between'} sx={{ pt: 4 }}>
                <Box
                    sx={{
                        mb: {
                            xs: 0,
                            md: 1,
                        },
                    }}
                >
                    <Breadcrumb items={['Marketplace', 'Produtos']} />
                </Box>
            </Stack>

            <CrudAPI
                name={name}
                route={AppRoutes.Products}
                endpoint={endpoint}
                crudEnableDelete={false}
                columns={columns}
                // TODO: fix this, is not default filtering properly
                defaultFilters={[
                    {
                        id: 'is_active',
                        value: true,
                    },
                ]}
                extraRowActions={extraRowActions}
            />

            {photosEntity && (
                <PhotosModal open={photosOpen} modalEntity={photosEntity} onClose={() => setPhotosOpen(false)} />
            )}
        </>
    );
};

export default Products;
