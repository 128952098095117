import React from 'react';

import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers';

import { TData } from 'src/components/crud/Crud.d';
import { CrudDatePickerProps } from 'src/components/crud/fields/CrudDatePicker/CrudDatePicker.d';

const CrudDatePicker = <T extends TData>(props: CrudDatePickerProps<T>) => {
    const { column, values, setValues, errors, readOnly } = props;

    const { accessorKey, header } = column;

    return (
        <DatePicker
            label={header}
            value={dayjs(values[accessorKey])}
            disabled={readOnly}
            onChange={(e: any) => {
                setValues({
                    ...values,
                    [accessorKey]: `${e['$y']}-${e['$M'] + 1}-${e['$D']}`,
                });
            }}
            slotProps={{
                textField: {
                    error: errors?.hasOwnProperty(accessorKey) ?? false,
                    helperText: errors ? errors[accessorKey] ?? '' : '',
                },
            }}
        />
    );
};

export default CrudDatePicker;
