import { Group } from 'src/pages/registry/persons/Persons';

export const METRICS_MAP = {
    1: 'Km Rodado',
    2: 'Média Km/L',
    3: 'Meta Individual de Média Km/L',
};

export interface Achievement {
    badge: string | File;
    begin: string;
    day_streak: number;
    description: string;
    end: string;
    goal: number;
    groups: Group[];
    has_day_streak: boolean;
    id: number;
    is_active: boolean;
    is_confirmed: boolean;
    is_cumulative: boolean;
    metric: 1 | 2 | 3;
    name: string;
    only_groups: boolean;
    score: number;
}
