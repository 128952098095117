import AppRoutes from 'src/routes/appRoutes';

// reports
import RankingReport from 'src/pages/reports/Ranking';

// dashboards
import DashboardPage from 'src/pages/dashboards/Dashboard';
import DashboardsGroupPage from 'src/pages/dashboards/DashboardsGroup';

// user
import UserSettings from 'src/pages/user/Settings';

// registers
import Achievements from 'src/pages/registry/achievements/Achievements';
import AllocationsPage from 'src/pages/registry/persons/Allocations';
import EnterprisesPage from 'src/pages/registry/enterprises/Enterprises';
import Goals from 'src/pages/registry/Goals';
import Groups from 'src/pages/registry/Groups';
import Persons from 'src/pages/registry/persons/Persons';
import VehiclesPage from 'src/pages/registry/vehicles/Vehicles';

// access
import Users from 'src/pages/access/Users';
import PermissionProfiles from 'src/pages/access/profiles/permissions/Profiles';
import DashboardProfilesPage from 'src/pages/access/profiles/dashboards/DashboardProfiles';
import KanbanProfilesPage from 'src/pages/access/profiles/kanban/KanbanProfiles';

// checklists
import Checklists from 'src/pages/checklists/Checklists';
import ChecklistRequests from 'src/pages/checklists/Checklists/ChecklistRequestsPage';

// marketplace
import ProductsPage from 'src/pages/marketplace/Products';
import PurchasesPage from 'src/pages/marketplace/Purchases';

// kanban
import KanbanPage from 'src/pages/kanban/KanbanPage';

// settings
import AdvanceEvents from 'src/pages/settings/AdvanceEvents';
import Contacts from 'src/pages/settings/contacts/Contacts/Contacts';
import DepartmentsPage from 'src/pages/settings/contacts/Departments';
import Events from 'src/pages/settings/Events';
import Settings from 'src/pages/settings/Settings';
import Settlements from 'src/pages/settings/Settlements';

const privatePages = [
    // USER
    {
        route: AppRoutes.UserSettings,
        component: UserSettings,
    },
    // REPORTS
    {
        route: AppRoutes.RankingReport,
        component: RankingReport,
    },
    // DASHBOARDS
    {
        route: AppRoutes.DashboardGroup,
        component: DashboardsGroupPage,
    },
    {
        route: AppRoutes.Dashboard,
        component: DashboardPage,
        fullFrame: true,
    },
    // ACCESS
    {
        route: AppRoutes.Users,
        component: Users,
    },
    {
        route: AppRoutes.PermissionProfiles,
        component: PermissionProfiles,
    },
    {
        route: AppRoutes.DashboardProfiles,
        component: DashboardProfilesPage,
    },
    {
        route: AppRoutes.KanbanProfiles,
        component: KanbanProfilesPage,
    },
    {
        route: AppRoutes.Vehicles,
        component: VehiclesPage,
    },
    // REGISTERS
    {
        route: AppRoutes.Achievements,
        component: Achievements,
    },
    {
        route: AppRoutes.Goals,
        component: Goals,
    },
    {
        route: AppRoutes.Groups,
        component: Groups,
    },
    {
        route: AppRoutes.Persons,
        component: Persons,
    },
    {
        route: AppRoutes.Allocations,
        component: AllocationsPage,
    },
    {
        route: AppRoutes.Enterprises,
        component: EnterprisesPage,
    },
    // CHECKLISTS
    {
        route: AppRoutes.Checklists,
        component: Checklists,
    },
    {
        route: AppRoutes.ChecklistsRequest,
        component: ChecklistRequests,
    },
    // MARKETPLACE
    {
        route: AppRoutes.Products,
        component: ProductsPage,
    },
    {
        route: AppRoutes.Purchases,
        component: PurchasesPage,
    },
    // KANBAN
    {
        route: AppRoutes.Kanban,
        component: KanbanPage,
    },
    {
        route: AppRoutes.KanbanBoard,
        component: KanbanPage,
    },
    // SETTINGS
    {
        route: AppRoutes.Contacts,
        component: Contacts,
    },
    {
        route: AppRoutes.Departments,
        component: DepartmentsPage,
    },
    {
        route: AppRoutes.Settings,
        component: Settings,
    },
    {
        route: AppRoutes.Events,
        component: Events,
    },
    {
        route: AppRoutes.Settlements,
        component: Settlements,
    },
    {
        route: AppRoutes.AdvanceEvents,
        component: AdvanceEvents,
    },
];

export default privatePages;
