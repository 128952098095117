import React, { useState } from 'react';

import { enqueueSnackbar } from 'notistack';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogContentText from '@mui/material/DialogContentText';
import Stack from '@mui/material/Stack';

import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import EditIcon from '@mui/icons-material/Edit';
import VerifiedIcon from '@mui/icons-material/Verified';
import VisibilityIcon from '@mui/icons-material/Visibility';
import WarningRoundedIconIcon from '@mui/icons-material/WarningRounded';

import useTenantAPI from 'src/services/api_tenant';
import AppRoutes from 'src/routes/appRoutes';
import { ACTIONS, hasRoutePermission } from 'src/services/storage/permissions';

import Breadcrumb from 'src/components/Breadcrumb';
import ConfirmModal from 'src/components/crud/modals/ConfirmModal/ConfirmModal';
import CrudAPI from 'src/components/crud/CrudPage/CrudPage';
import CrudTableBtn from 'src/components/crud/CrudTableBtn/CrudTableBtn';

import BadgeField from 'src/pages/registry/achievements/components/fields/BadgeField';
import ConfirmContent from 'src/pages/registry/achievements/components/ConfirmContent/ConfirmContent';
import DayStreakField from 'src/pages/registry/achievements/components/fields/DayStreakField';
import GoalField from 'src/pages/registry/achievements/components/fields/GoalField';
import GroupsField from 'src/pages/registry/achievements/components/fields/GroupsField';
import HasDayStreakField from 'src/pages/registry/achievements/components/fields/HasDayStreakField';
import IsCumulativeField from 'src/pages/registry/achievements/components/fields/IsCumulativeField';

import { Achievement, METRICS_MAP } from './Achievements.d';

const AchievementsPage = () => {
    const api_tenant = useTenantAPI();

    /**
     * MANAGE CREATE/UPDATE MODAL's
     */
    const [createUpdateOpen, setCreateUpdateOpen] = useState(false);
    const [modalEntity, setModalEntity] = useState<Achievement | null>(null);

    const openCreateUpdate = (entity: Achievement) => {
        setCreateUpdateOpen(true);
        setModalEntity(entity);
    };

    /**
     * MANAGE DEACTIVATE MODAL's
     */
    const [deactivateOpen, setDeactivateOpen] = useState(false);

    const openDeactivate = (entity: Achievement) => {
        setDeactivateOpen(true);
        setModalEntity(entity);
    };

    const handleDeactivateClose = () => {
        setDeactivateOpen(false);
    };

    const handleDeactivateSubmit = () => {
        if (modalEntity) {
            api_tenant
                .delete(`/api/achievements/${modalEntity.id}/`)
                .then(() => {
                    setDeactivateOpen(false);
                    setModalEntity(null);
                    setRefreshCrudTable((e) => !e);
                })
                .catch((error) => {
                    try {
                        enqueueSnackbar(error.response.data.detail, { variant: 'error' });
                    } catch (e) {
                        enqueueSnackbar('Erro ao desativar a conquista!', { variant: 'error' });
                    }
                });
        }
    };

    /**
     * MANAGE CONFIRM MODAL
     */
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [refreshCrudTable, setRefreshCrudTable] = useState(false);

    const openConfirmModal = (entity: Achievement) => {
        setModalEntity(entity);
        setConfirmModalOpen(true);
    };

    /**
     * HANDLE GROUP MODAL BUTTONS
     */
    const handleConfirmModalClose = () => {
        setConfirmModalOpen(false);
    };

    const handleConfirmModalSubmit = async () => {
        if (modalEntity) {
            await api_tenant
                .put(`/api/achievements/${modalEntity.id}/confirm/`)
                .then(() => {
                    setConfirmModalOpen(false);
                    setModalEntity(null);
                    setRefreshCrudTable((e) => !e);
                })
                .catch((error) => {
                    try {
                        enqueueSnackbar(error.response.data.detail, { variant: 'error' });
                    } catch (e) {
                        enqueueSnackbar('Erro ao confirmar o cadastro da conquista!', { variant: 'error' });
                    }
                });
        }
    };

    /**
     * CUSTOM PAYLOAD FORMAT
     */
    const formatCreateUpdatePayload = (formData: FormData, entity_id: number, values: Record<string, any>) => {
        // If the badge is not a file, remove from the payload
        if (!(formData.get('badge') instanceof File)) {
            // Remove the badge from the payload since the image already is on the server
            formData.delete('badge');
        }
        return formData;
    };

    /**
     * CONFIRM MODAL ACTIONS
     */
    const handleOpenCreateUpdate = () => {
        setConfirmModalOpen(false);
        if (modalEntity) {
            openCreateUpdate(modalEntity);
        }
    };

    /**
     * CUSTOM ROW ACTIONS
     */
    const getIsReadOnly = (entity: Achievement) => {
        return entity.is_confirmed || !entity.is_active;
    };

    const rowActions = (entity: Achievement) => {
        const readOnly = getIsReadOnly(entity);
        const enableUpdate = hasRoutePermission(AppRoutes.Achievements, ACTIONS.UPDATE);
        const enableDelete = hasRoutePermission(AppRoutes.Achievements, ACTIONS.DELETE);

        const actions = [];

        if (!entity.is_confirmed && entity.is_active && enableUpdate) {
            actions.push(
                <CrudTableBtn
                    key={'confirm-row-btn'}
                    entity={entity}
                    onClick={openConfirmModal}
                    tooltip={'Ativar Conquista'}
                    icon={VerifiedIcon}
                    color={'primary'}
                />
            );
        }

        actions.push(
            <CrudTableBtn
                key={'edit-row-btn'}
                entity={entity}
                onClick={openCreateUpdate}
                tooltip={readOnly ? 'Visualizar' : 'Editar'}
                icon={readOnly ? VisibilityIcon : EditIcon}
            />
        );

        if (entity.is_active && enableDelete) {
            actions.push(
                <CrudTableBtn
                    key={'delete-row-btn'}
                    entity={entity}
                    onClick={openDeactivate}
                    tooltip={'Desativar'}
                    icon={DisabledByDefaultIcon}
                    color={'error'}
                />
            );
        }

        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'end',
                }}
            >
                {actions}
            </Box>
        );
    };

    return (
        <>
            <Stack direction={'row'} justifyContent={'space-between'} sx={{ pt: 4 }}>
                <Box
                    sx={{
                        mb: {
                            xs: 0,
                            md: 1,
                        },
                    }}
                >
                    <Breadcrumb items={['Cadastros', 'Conquistas']} />
                </Box>
            </Stack>

            <CrudAPI
                // CRUD INFO
                name={{
                    singular: 'Conquista',
                    plural: 'Conquistas',
                }}
                route={AppRoutes.Achievements}
                endpoint={'/api/achievements/'}
                columns={[
                    {
                        accessorKey: 'basic_info',
                        header: 'Informações Básicas',
                        hideColumn: true,
                        field: {
                            type: 'header',
                        },
                    },
                    {
                        accessorKey: 'name',
                        header: 'Nome',
                    },
                    {
                        accessorKey: 'description',
                        header: 'Descrição',
                        hideColumn: true,
                        field: {
                            type: 'text',
                            multiline: true,
                        },
                    },
                    {
                        accessorKey: 'badge',
                        header: 'Medalha',
                        hideColumn: true,
                        field: {
                            type: 'custom',
                            component: BadgeField,
                        },
                    },
                    {
                        accessorKey: 'scope',
                        header: 'Escopo',
                        hideColumn: true,
                        field: {
                            type: 'header',
                            separator: true,
                        },
                    },
                    {
                        accessorKey: 'begin',
                        header: 'Início da Vigência',
                        hideColumn: true,
                        field: {
                            type: 'date',
                        },
                    },
                    {
                        accessorKey: 'end',
                        header: 'Fim da Vigência',
                        hideColumn: true,
                        field: {
                            type: 'date',
                        },
                    },
                    {
                        accessorKey: 'only_groups',
                        header: 'Apenas grupos selecionados',
                        hideColumn: true,
                        field: {
                            type: 'check',
                        },
                    },
                    {
                        accessorKey: 'groups',
                        header: 'Grupos de Motoristas',
                        hideColumn: true,
                        field: {
                            type: 'custom',
                            component: GroupsField,
                        },
                    },
                    {
                        accessorKey: 'score-header',
                        header: 'Pontuação',
                        hideColumn: true,
                        field: {
                            type: 'header',
                            separator: true,
                        },
                    },
                    {
                        accessorKey: 'score',
                        header: 'Quantidade de Pontos',
                        hideColumn: true,
                        field: {
                            type: 'number',
                            step: 50,
                        },
                    },
                    {
                        accessorKey: 'indicator',
                        header: 'Indicador',
                        hideColumn: true,
                        field: {
                            type: 'header',
                            separator: true,
                        },
                    },
                    {
                        accessorKey: 'metric',
                        header: 'Indicador',
                        Cell: ({ row }) => METRICS_MAP[row.original.metric],
                        field: {
                            type: 'select',
                            options: METRICS_MAP,
                            default: 2,
                        },
                    },
                    {
                        accessorKey: 'goal',
                        header: 'Meta',
                        hideColumn: true,
                        field: {
                            type: 'custom',
                            component: GoalField,
                        },
                    },
                    {
                        accessorKey: 'is_cumulative',
                        header: 'Meta acumulada',
                        hideColumn: true,
                        field: {
                            type: 'custom',
                            component: IsCumulativeField,
                        },
                    },
                    {
                        accessorKey: 'has_day_streak',
                        header: 'Meta de dias consecutivos',
                        hideColumn: true,
                        field: {
                            type: 'custom',
                            component: HasDayStreakField,
                        },
                    },
                    {
                        accessorKey: 'day_streak',
                        header: 'Dias Consecutivos',
                        hideColumn: true,
                        field: {
                            type: 'custom',
                            component: DayStreakField,
                        },
                    },
                    {
                        accessorKey: 'is_active',
                        header: 'Ativo',
                        enableEditing: false,
                        type: 'check',
                    },
                    {
                        accessorKey: 'end_separator',
                        header: '',
                        hideColumn: true,
                        field: {
                            type: 'header',
                            separator: true,
                        },
                    },
                ]}
                // ACESSOR TABLE FUNCTIONS
                controlledRefresh={refreshCrudTable}
                setControlledRefresh={setRefreshCrudTable}
                // PAYLOAD
                formatPayload={formatCreateUpdatePayload}
                // ROW ACTIONS
                rowActions={rowActions}
                getIsReadOnly={getIsReadOnly}
                // MODALS ENTITY
                controlledModalEntity={modalEntity}
                setControlledModalEntity={setModalEntity}
                // CREATE/UPDATE MODAL
                controlledCreateUpdateOpen={createUpdateOpen}
                setControlledCreateUpdateOpen={setCreateUpdateOpen}
            />

            {/** CONFIRM ACHIEVEMENT MODAL */}
            <ConfirmModal
                open={confirmModalOpen}
                entity={modalEntity ?? null}
                title={'Confirmar o cadastro?'}
                btnText={'Confirmar'}
                onCancel={handleConfirmModalClose}
                onSubmit={handleConfirmModalSubmit}
                extraActions={[
                    <Button onClick={handleOpenCreateUpdate} variant={'contained'} color={'primary'} key={'edit-btn'}>
                        <ArrowBackRoundedIcon />
                        Editar
                    </Button>,
                ]}
            >
                <DialogContentText sx={{ mb: 3 }}>
                    Verifique as informações da conquista antes de confirmá-la.
                </DialogContentText>
                {modalEntity && <ConfirmContent entity={modalEntity} />}
                <DialogContentText
                    sx={{
                        textAlign: 'justify',
                        my: 4,
                    }}
                >
                    Ao confirmar, a conquista ficará visível no aplicativo. Os pontos recebidos da conquista não poderam
                    ser removidos.
                </DialogContentText>
                <DialogContentText
                    sx={{
                        textAlign: 'right',
                        mt: 2,
                        color: 'red',
                    }}
                >
                    <WarningRoundedIconIcon />
                    <small> Após a confirmação não será mais possível editar esta conquista.</small>
                </DialogContentText>
            </ConfirmModal>

            {/** CONFIRM DELETE MODAL */}
            <ConfirmModal
                open={deactivateOpen}
                entity={modalEntity ?? null}
                title={`Desativar a conquista ${modalEntity?.name}?`}
                message={'Ao desativar a conquista, ela não ficará mais visível no aplicativo.'}
                warningMessage={'Esta ação não poderá ser revertida!'}
                btnText={'Desativar'}
                onCancel={handleDeactivateClose}
                onSubmit={handleDeactivateSubmit}
            />
        </>
    );
};

export default AchievementsPage;
