import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import useTenantAPI from 'src/services/api_tenant';

import { TData } from 'src/components/crud/Crud.d';
import CrudForm from 'src/components/crud/CrudForm';

import { SettingsSectionProps } from './SettingsSection.d';

const SettingsSection = <T extends TData>(props: SettingsSectionProps<T>) => {
    const { endpoint, title, subtitle, columns, extraFields } = props;

    const api_tenant = useTenantAPI();
    const { enqueueSnackbar } = useSnackbar();

    /**
     * SECTION CONTROL
     */
    const [values, setValues] = useState<Record<string, any>>({});
    const [errors, setErrors] = useState<Record<string, any>>({});

    /**
     * DISABLED MODULES
     */
    const sectionDisabled = columns.every((c) => c.disabledModule);

    /**
     * EVENT HANDLERS
     */
    const handleSubmit = async () => {
        if (sectionDisabled) {
            return;
        }

        await api_tenant
            .put(endpoint, values)
            .then(() => {
                enqueueSnackbar('Informações atualizadas.', { variant: 'success' });
                setErrors({});
            })
            .catch((error) => {
                setErrors(error.response.data);
                enqueueSnackbar(`Erro ao atualizar informações.`, { variant: 'error' });
            });
    };

    const handleCancel = () => {
        window.location.reload();
    };

    /**
     * GET CONFIGURATION DATA ON PAGE LOAD
     */
    useEffect(() => {
        if (sectionDisabled) {
            setValues({});
            return;
        }
        api_tenant.get(endpoint).then((res) => setValues({ ...res.data }));
    }, []);

    /**
     * RE-BUILD COLUMNS LIST
     */
    const crudColumns = columns.map((c) => {
        c.enableEditing = true;
        c.readOnly = c.disabledModule;
        return c;
    });

    return (
        <>
            <Grid item xs={12} sm={4}>
                <Typography variant={'h5'} sx={{ mb: 2 }}>
                    {title}
                </Typography>
                <Typography>{subtitle}</Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack
                        sx={{
                            width: '100%',
                            minWidth: {
                                xs: '300px',
                                sm: '360px',
                                md: '400px',
                            },
                            gap: '1.5rem',
                        }}
                    >
                        {values ? (
                            <CrudForm columns={crudColumns} values={values} setValues={setValues} errors={errors} />
                        ) : (
                            <Stack direction={'row'} justifyContent={'center'}>
                                <CircularProgress />
                            </Stack>
                        )}
                        <Stack direction={'row'} gap={2}>
                            <Button
                                variant={'outlined'}
                                color={'primary'}
                                onClick={handleCancel}
                                disabled={sectionDisabled}
                            >
                                Cancelar
                            </Button>
                            <Button
                                variant={'contained'}
                                color={'primary'}
                                onClick={handleSubmit}
                                disabled={sectionDisabled}
                            >
                                Salvar
                            </Button>
                        </Stack>
                    </Stack>
                </form>
                <Grid sx={{ mt: 2 }}>{extraFields}</Grid>
            </Grid>
            <Grid item xs={12}>
                <Divider
                    sx={{
                        my: 4,
                        bgcolor: 'secondary.light',
                    }}
                />
            </Grid>
        </>
    );
};

export default SettingsSection;
