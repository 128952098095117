import React, { useEffect, useState } from 'react';

import 'dayjs/locale/pt-br';
import { useMutation } from '@tanstack/react-query';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';

import SaveIcon from '@mui/icons-material/Save';

import CrudForm from 'src/components/crud/CrudForm';
import setNestedValue from 'src/components/utils/setNestedValue';
import { Column, TData } from 'src/components/crud/Crud.d';

import { CrudModalProps } from './CrudModal.d';

const CrudModal = <T extends TData>(props: CrudModalProps<T>) => {
    const {
        // CONTROL
        open,
        entity,
        errors,
        readOnly = false,

        // STRUCTURE
        columns,

        // EVENTS
        onCancel,
        onSubmit,

        // ACTIONS
        extraActions,

        // TEXT
        readTitle,
        createTitle,
        updateTitle,

        // VISUAL
        size = 'sm',
    } = props;

    const [values, setValues] = useState({});

    /**
     * LISTEN TO ENTITY CHANGE AND FILL THE FORM FIELDS
     */
    const getColumnKey = (column: Column<T>) => {
        if ('field' in column && column.field && 'name' in column.field && column.field.name) {
            return column.field.name;
        }

        return column.accessorKey;
    };

    useEffect(() => {
        if (open) {
            // Clear all field values
            let newValues = columns.reduce((acc, column) => {
                const key = getColumnKey(column);
                const type = column.field?.type ?? '';

                if (key === 'id' || type === 'header') {
                    return acc;
                }

                const default_value = ['search', 'transfer'].includes(type) ? [] : '';
                setNestedValue(acc, key, column.field?.default ?? default_value);

                return acc;
            }, {});

            if (entity) {
                newValues = {
                    ...newValues,
                    ...Object.fromEntries(Object.entries(entity).filter(([_, v]) => v)),
                };
            }

            setValues(newValues);
        }
    }, [entity, open]);

    /**
     * SUBMIT CONTROL
     */
    const submitData = async () => {
        let formData = new FormData();

        for (let [key, val] of Object.entries(values)) {
            const value = getFieldValue(val);
            const column = columns.find((c) => getColumnKey(c) === key) as Column<T>;
            const columnType = column?.field?.type;

            if (value || value === 0 || columnType === 'search' || columnType === 'custom') {
                if (Array.isArray(value)) {
                    value.forEach((val) => formData.append(key, val));
                } else if (value === undefined) {
                    formData.append(key, '');
                } else {
                    formData.append(key, value);
                }
            }
        }

        await onSubmit(formData, entity?.id ?? null, values as T);
    };

    const { mutateAsync: handleSubmit, isLoading: isSubmitLoading } = useMutation({
        mutationFn: submitData,
    });

    /**
     * EVENT HANDLERS
     */

    const handleCancel = () => onCancel();

    const getFieldValue = (field: any) => {
        if (field === null) {
            return;
        }

        // Object, but not array or file
        if (typeof field === 'object' && !(Array.isArray(field) || field instanceof File)) {
            return field.id;
        }

        if (Array.isArray(field)) {
            field = field.map((f) => getFieldValue(f));
        }

        return field;
    };

    /**
     * COMPONENT RENDER
     */
    if (Object.keys(values).length === 0) {
        return <></>;
    }

    return (
        <Dialog
            open={open}
            fullWidth={true}
            maxWidth={size}
            onTransitionEnd={() => {
                if (!open) {
                    setValues({});
                }
            }}
        >
            <DialogTitle textAlign={'center'}>{readOnly ? readTitle : entity ? updateTitle : createTitle}</DialogTitle>
            <DialogContent sx={{ pt: 1 }}>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack
                        sx={{
                            py: 1,
                            width: '100%',
                            minWidth: {
                                xs: '300px',
                                sm: '360px',
                                md: '400px',
                            },
                            gap: '1.5rem',
                        }}
                    >
                        {Object.keys(values).length > 0 && (
                            <CrudForm
                                columns={columns}
                                values={values}
                                setValues={setValues}
                                errors={errors}
                                readOnly={readOnly}
                            />
                        )}
                    </Stack>
                </form>
            </DialogContent>
            <DialogActions
                sx={{
                    p: '1.25rem',
                    display: 'flex',
                    backgroundColor: '#ECECEC',
                    justifyContent: extraActions ? 'space-between' : 'end',
                }}
            >
                {extraActions && <Box children={extraActions} />}
                <Stack direction={'row'} spacing={1}>
                    <Button disabled={isSubmitLoading} onClick={handleCancel} children={'Cancelar'} />
                    {!readOnly && (
                        <Button
                            color={'primary'}
                            variant={'contained'}
                            disabled={isSubmitLoading || !open}
                            onClick={() => handleSubmit()}
                            startIcon={
                                isSubmitLoading ? <CircularProgress size={15} /> : <SaveIcon fontSize={'small'} />
                            }
                            children={'Salvar'}
                        />
                    )}
                </Stack>
            </DialogActions>
        </Dialog>
    );
};

export default CrudModal;
